<template>
  <div class="index-css">
    <div class="container">
      <div class="img">
        <img src="../../assets/evaluation/background2.png" />
        <div class="text">
          <p>1、因充分考虑到企业间所处的行业差距，成效性指标计算公式可能不同，故这里不给出计算公式，请企业根据自己的实际情况填写各成效类指标数值和计算公式</p>
          <p>2、如果企业有除以下6项指标的其他指标，请在“其他”中填写</p>
          <p>3、以下选项皆为必填</p>
        </div>
      </div>
      <div class="box">
        <div class="input-box">
          <span>运营成本降低率：</span>
          <div class="input">
            <input type="text" v-model="ocrRate" />
            <span>%</span>
          </div>
          <span>
            参考公式：（上一期运营成本-当期运营成本）上一期运营成本
            <br />数据要求：可以参考2019年、2020年
          </span>
        </div>
        <div class="input-box">
          <span>产品研制周期缩短率：</span>
          <div class="input">
            <input type="text" v-model="spdcRate" />
            <span>%</span>
          </div>
          <span>
            参考公式：[1-（当期产品研发周期/上一期产品研发周期）]*100%
            <br />数据要求：可以参考2019年、2020年
          </span>
        </div>
        <div class="input-box">
          <span>人均生产效率提高率：</span>
          <div class="input">
            <input type="text" v-model="pcpiRate" />
            <span>%</span>
          </div>
          <span>
            参考公式：{[（本年度实际产出/本年度理论最大产出）/本年度员工人数]/...
            <br />数据要求：可以参考2019年、2020年
          </span>
        </div>
        <div class="input-box">
          <span>产品不良品率降低率：</span>
          <div class="input">
            <input type="text" v-model="rrodpRate" />
            <span>%</span>
          </div>
          <span>
            参考公式：{[（本年度实际产出/本年度理论最大产出）/本年度员工人数]/...
            <br />数据要求：可以参考2019年、2020年
          </span>
        </div>
        <div class="input-box">
          <span>能源利用提高率：</span>
          <div class="input">
            <input type="text" v-model="euiRate" />
            <span>%</span>
          </div>
          <span>
            参考公式：{[（本年度实际产出/本年度理论最大产出）/本年度员工人数]/...
            <br />数据要求：可以参考2019年、2020年
          </span>
        </div>
        <div class="input-box">
          <span>关键工序数控化率：</span>
          <div class="input">
            <input type="text" v-model="nckpRate" />
            <span>%</span>
          </div>
          <span>
            参考公式：{[（本年度实际产出/本年度理论最大产出）/本年度员工人数]/...
            <br />数据要求：可以参考2019年、2020年
          </span>
        </div>
        <div class="input-box">
          <span>设备联网率：</span>
          <div class="input">
            <input type="text" v-model="enRate" />
            <span>%</span>
          </div>
          <span>
            参考公式：{[（本年度实际产出/本年度理论最大产出）/本年度员工人数]/...
            <br />数据要求：可以参考2019年、2020年
          </span>
        </div>
        <div class="input-box">
          <span>产品一次检验合格率：</span>
          <div class="input">
            <input type="text" v-model="fppiRate" />
            <span>%</span>
          </div>
          <span>
            参考公式：{[（本年度实际产出/本年度理论最大产出）/本年度员工人数]/...
            <br />数据要求：可以参考2019年、2020年
          </span>
        </div>
        <div class="input-box">
          <span>设备综合应用效率：</span>
          <div class="input">
            <input type="text" v-model="caeeRate" />
            <span>%</span>
          </div>
          <span>
            参考公式：{[（本年度实际产出/本年度理论最大产出）/本年度员工人数]/...
            <br />数据要求：可以参考2019年、2020年
          </span>
        </div>
        <div class="input-box">
          <span>产品数量管理工具普及率：</span>
          <el-select v-model="tppdmTools" placeholder @change="tppdmToolsInput">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span>指标说明：是否可以实现基于POM，PLM，CAPP等设计数据管理系统对设计数据或文档的结构</span>
        </div>
        <div class="input-box">
          <span>生产流程和管理信息化程度：</span>
          <el-select v-model="ilppaTools" placeholder @change="ilppaToolsInput">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span>指标说明：是否可以实现基于POM、PLM、CAPP等设计数据管理系统对设计数据或文档的结构</span>
        </div>
        <div class="input-box">
          <span>智能排产应用程度：</span>
          <el-select v-model="asipdTools" placeholder @change="asipdToolsInput">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span>指标说明：是否可以实现基于POM、PLM、CAPP等设计数据管理系统对设计数据或文档的结构</span>
        </div>
        <div class="input-box">
          <span>生成作业智能化程度：</span>
          <el-select v-model="idpoTools" placeholder @change="idpoToolsInput">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span>指标说明：是否可以实现基于POM、PLM、CAPP等设计数据管理系统对设计数据或文档的结构</span>
        </div>
        <div class="input-box">
          <span>质量管理与追溯智能化程度：</span>
          <el-select v-model="idqmtTools" placeholder @change="idqmtToolsInput">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span>指标说明：是否可以实现基于POM、PLM、CAPP等设计数据管理系统对设计数据或文档的结构</span>
        </div>
        <div class="input-box">
          <span>供应链上下游信息集成与协同：</span>
          <el-select v-model="iicudscTools" placeholder @change="iicudscToolsInput">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span>指标说明：是否可以实现基于POM、PLM、CAPP等设计数据管理系统对设计数据或文档的结构</span>
        </div>
        <div class="input-box">
          <span>厂内仓储自动化程度：</span>
          <el-select v-model="adwifTools" placeholder @change="adwifToolsInput">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span>指标说明：是否可以实现基于POM、PLM、CAPP等设计数据管理系统对设计数据或文档的结构</span>
        </div>
        <div class="input-box">
          <span>厂内配送自动化程度：</span>
          <el-select v-model="addifTools" placeholder @change="addifToolsInput">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>
          <span>指标说明：是否可以实现基于POM、PLM、CAPP等设计数据管理系统对设计数据或文档的结构</span>
        </div>
      </div>
      <el-button type="primary" @click="submitInport">提交</el-button>
    </div>
  </div>
</template>

<script>
import { post } from "../../api/http";
export default {
  data() {
    return {
      headerColor: "#5A8BFF",
      options: [{ label: "是" }, { label: "否" }],
      ocrRate: "", // 数字类型
      spdcRate: "",
      pcpiRate: "",
      rrodpRate: "",
      euiRate: "",
      nckpRate: "",
      enRate: "",
      fppiRate: "",
      caeeRate: "",
      answerGuId: JSON.parse(localStorage.getItem("answerGuId")), // 字符串类型
      tppdmTools: "",
      ilppaTools: "",
      asipdTools: "",
      idpoTools: "",
      idqmtTools: "",
      iicudscTools: "",
      adwifTools: "",
      addifTools: ""
    };
  },
  methods: {
    tppdmToolsInput(e) {
      this.tppdmTools = e;
    },
    ilppaToolsInput(e) {
      this.ilppaTools = e;
    },
    asipdToolsInput(e) {
      this.asipdTools = e;
    },
    idpoToolsInput(e) {
      this.idpoTools = e;
    },
    idqmtToolsInput(e) {
      this.idqmtTools = e;
    },
    iicudscToolsInput(e) {
      this.iicudscTools = e;
    },
    adwifToolsInput(e) {
      this.adwifTools = e;
    },
    addifToolsInput(e) {
      this.addifTools = e;
    },
    submitInport() {
      var data = {};
      data.ocrRate = Number(this.ocrRate);
      data.spdcRate = Number(this.spdcRate);
      data.pcpiRate = Number(this.pcpiRate);
      data.rrodpRate = Number(this.rrodpRate);
      data.euiRate = Number(this.euiRate);
      data.nckpRate = Number(this.nckpRate);
      data.enRate = Number(this.enRate);
      data.fppiRate = Number(this.fppiRate);
      data.caeeRate = Number(this.caeeRate);
      data.answerGuId = this.answerGuId;
      data.tppdmTools = this.tppdmTools;
      data.ilppaTools = this.ilppaTools;
      data.asipdTools = this.asipdTools;
      data.idpoTools = this.idqmtTools;
      data.iicudscTools = this.iicudscTools;
      data.adwifTools = this.adwifTools;
      data.addifTools = this.addifTools;
      post("/Exponent", data)
        .then(resp => {
          if (resp.data.code == 200) {
            this.$message({
              message: "提交成功",
              type: "success"
            });
            localStorage.removeItem('answerStatus')
            this.$router.push("./evaluationreport");
          } else {
            this.$message.error(resp.data.message);
          }
        })
        .catch(() => {
          this.$message.error("提交失败");
        });
    }
  }
};
</script>

<style>
.index-css .container {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  margin-top: 20px;
}
.index-css .container .img {
  background-color: white;
  position: relative;
}
.index-css .container img {
  width: 100%;
}
.index-css .text {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: start;
  color: white;
}
.index-css .text p {
  padding-left: 45px;
  margin-bottom: 10px;
}
.index-css .container .box {
  border-left: 5px solid #5aa2ffcb;
  border-right: 5px solid #5aa2ffcb;
  border-bottom: 5px solid #5aa2ffcb;
  margin-top: -5px;
  padding: 10px 0 20px 0;
  background-color: white;
}
.index-css .input-box {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 17px;
}
.index-css .input-box .input {
  border: 1px solid #d4d2d28f;
  padding: 8px 10px;
  width: 180px;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
}
.index-css .input-box .input > input {
  width: 180px;
  border: none !important;
  background-color: #f7f7f7;
}
.index-css .input-box > span:nth-of-type(1) {
  width: 180px;
  text-align: end;
}
.index-css .input-box > span:nth-of-type(2) {
  background-color: #fffdeb;
  width: 450px;
  line-height: 20px;
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #d5cf9b;
  margin-left: 12px;
  text-align: start;
  color: #999999;
  font-size: 11px !important;
}
.input-box .el-input__inner {
  height: 35px !important;
  padding: 0 10px !important;
  background-color: #f7f7f7 !important;
  border: 1px solid #d4d2d28f;
  border-radius: 0;
}
.input-box .el-input__icon {
  line-height: 35px !important;
}
.input-box .el-input {
  width: 200px !important;
}
.index-css .el-button {
  width: 100px;
  margin: 35px !important;
}
</style>